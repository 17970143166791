import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Editor} from "@tinymce/tinymce-react";
import cn from "classnames";
import dayjs from "dayjs";
import GoogleMapReact from "google-map-react";
import i18n from "i18next";
import _ from "lodash"
import React, {useCallback, useEffect, useRef, useState} from "react";
import DatePicker from "react-datepicker";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {NotificationManager} from "react-notifications";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {getAllParkingZoneAdmins} from "../../actions/admin";
import {getAllParkingZoneManagers, getManagersByAdminId,} from "../../actions/manager";
import {addParkingZone, geocodeAddressSetMarkers} from "../../actions/parkingZone";
import {getAllViewers, getViewersByAdminId} from "../../actions/viewer";
import AddressesComponent, {formatAddress} from "../../common/address/AddressesComponent";
import XIcon from "../../common/assets/x-icon.svg";
import Button from "../../common/button/button";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import TextareaWithLabel from "../../common/input/textareaWithLabel";
import TimePickerInputWithLabel from "../../common/input/timePickerInputWithLabel";
import LabelWithHR from "../../common/labels/labelWithHR";
import {ADDRESS_GEOCODING_DELAY, defaultMarker, manualParkingZoneMarkerEnabled, ROLES} from "../../consts";
import {newModalStyle} from "../../reusable/styles";
import {formatCurrencyCode} from "../../utils/formatter";
import {mapUser} from "../../utils/mapUser";
import Style from "./addParkingZoneModal.module.css";
import Marker, {colorMapOptions, dimmedMapOptions, moveMapCenterByCountry} from "./marker";
import SelectWithLabel from "../../common/input/selectWithLabel";
import { parkingViolationTypes } from "../../globals";

const AddParkingZoneModal = (props) => {
  const { handleSubmit, register, watch, control, setValue } = useForm();
  const { t } = useTranslation();
  const price = watch("price");
  const paymentPeriod = watch("paymentPeriod");
  const reminderPaymentPeriod = watch("reminderPaymentPeriod");
  const lastReminderPaymentPeriod = watch("lastReminderPaymentPeriod");
  const reminderFee = watch("reminderFee");
  const lastReminderFee = watch("lastReminderFee");
  const additionalCost = watch("additionalCost");
  const numberOfParkingPlaces = watch("numberOfParkingPlaces");
  const parkingZoneName = watch("parkingZoneName");
  const staticPinCode = watch("staticPinCode");
  const payee = watch("payee");
  const iban = watch("iban");
  const parkingPayZone = watch("parkingPayZone");
  const customer = watch("customer");
  const bic = watch("bic");
  const notedBy = watch("notedBy");
  const injuredPerson = watch("injuredPerson");
  const representedBy = watch("representedBy");
  const relationToProperty = watch("relationToProperty");
  const additionalInfo = watch("additionalInfo");
  const countryWatch = watch("country", "0");
  const salesArea = watch("salesArea");
  const pzComment = watch("pzComment");
  const financialInstitution = watch("financialInstitution");
  const [address, setAddress] = useState(null);
  const dispatch = useDispatch();
  const { admins } = useSelector((state) => state.admin);
  const { managers } = useSelector((state) => state.manager);
  const { viewers } = useSelector((state) => state.viewer);
  const { clients } = useSelector((state) => state.client);
  const editorRef = useRef(null);
  const [clientId, setClientId] = useState("");
  const [useClientDocs, setUseClientDocs] = useState(false);
  const [powerOfAttorneyName, setPowerOfAttorneyName] = useState("");
  const [landRegisterName, setLandRegisterName] = useState("");
  const [documentation, setDocumentation] = useState(null);
  const [documentationName, setDocumentationName] = useState("");
  const [parkingPhotoName, setParkingPhotoName] = useState("");
  const [powerOfAttorney, setPowerOfAttorney] = useState(null);
  const [landRegister, setLandRegister] = useState(null);
  const [parkingPhoto, setParkingPhoto] = useState(null);
  const [keycloakAdminId, setKeycloakAdminId] = useState("0");
  const [managersIds, setManagersIds] = useState([]);
  const [viewersIds, setViewersIds] = useState([]);
  const [managersComboBoxNames, setManagersComboBoxNames] = useState([]);
  const [viewersComboBoxNames, setViewersComboBoxNames] = useState([]);
  const [counter, setCounter] = useState(0);
  const [viewerCounter, setViewerCounter] = useState(0);
  const [objectionEnabled, setObjectionEnabled] = useState(true);
  const [automaticSignedOut, setAutomaticSignedOut] = useState(
    new Date("2022-01-26 05:00")
  );
  const [markers, setMarkers] = useState([]);
  const [viacarIntegrationImmediately, setViacarIntegrationImmediately] =
    useState(false);
  const [videoAnalyzed, setVideoAnalyzed] =
      useState(false);

  const [additionalInfoForTickets, setAdditionalInfoForTickets] =
    useState(false);
  const auth = useAuth();
  const loggedUser = mapUser(auth);
  const isLoggedUserSysadmin = loggedUser?.roles?.includes(ROLES.SYSADMIN);
  const [dateOfCourtDecision, setDateOfCourtDecision] = useState(null);
  const [contractDate, setContractDate] = useState(null);
  const [publicationInCantonalNewspaper, setPublicationInCantonalNewspaper] =
    useState(null);

  const [parkingPermitEnabled, setParkingPermitEnabled] = useState(false);
  const [parkingPermitTime, setParkingPermitTime] = useState(null);
  const [freezeTime, setFreezeTime] = useState(null);
  const [useMarkerForProximityCheck, setUseMarkerForProximityCheck] = useState(false);
  const parkingViolationTypeWatch = watch("parkingViolationType");

  const [geocoder, setGeocoder] = useState(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (isLoggedUserSysadmin) {
      dispatch(getAllParkingZoneAdmins());
      dispatch(getAllParkingZoneManagers());
      dispatch(getAllViewers());
    } else {
      dispatch(getManagersByAdminId());
      dispatch(getViewersByAdminId());
    }
  }, [dispatch, isLoggedUserSysadmin]);

  const isAdminSelected = () => {
    return isLoggedUserSysadmin ? keycloakAdminId === "0" : false;
  };

  const isSubmitDisabled =
    !address ||
    !numberOfParkingPlaces ||
    !price ||
    !paymentPeriod ||
    !reminderFee ||
    !lastReminderFee ||
    !additionalCost ||
    !reminderPaymentPeriod ||
    !lastReminderPaymentPeriod ||
    isAdminSelected() ||
    managersIds?.includes("0") ||
    viewersIds?.includes("0") ||
    (parkingPermitEnabled &&
      parkingPermitTime?.$H * 60 + parkingPermitTime?.$m === 0) ||
    (parkingPermitEnabled && freezeTime?.$H * 60 + freezeTime?.$m === 0);

  const documentUpload = (e, files, setFiles, filesNames, setFilesNames) => {
    if (e.target.files.length !== 0) {
      let fileList = files === null ? [] : files;
      for (let i = 0; i < e.target.files.length; i++) {
        fileList.push(e.target.files[i]);
      }
      setFiles(fileList);

      let names = filesNames;
      for (let i = 0; i < e.target.files.length; i++) {
        if (names === "" && i === 0) {
          names = names.concat(e.target.files[i].name);
        } else {
          names = names.concat("|", e.target.files[i].name);
        }
      }
      setFilesNames(names);
    }
  };

  useEffect(() => {
    if (parkingPermitEnabled === true) {
      setParkingPermitTime(dayjs("2022-04-17T01:00"));
      setFreezeTime(dayjs("2022-04-17T00:15"));
    } else {
      setParkingPermitTime(null);
      setFreezeTime(null);
    }
  }, [parkingPermitEnabled]);

  useEffect(() => {
    if (!!videoAnalyzed) {
      // clear useMarkerForProximityCheck if this is video analyzed PZ
      setUseMarkerForProximityCheck(false)
    } else if (!markers?.size) {
      // clear useMarkerForProximityCheck if there are no markers
      setUseMarkerForProximityCheck(false)
    }
  }, [markers, videoAnalyzed]);

  const removeDocument = (
    files,
    setFiles,
    filesNames,
    setFilesNames,
    index
  ) => {
    setFiles([...files?.filter((f, i) => i !== index)]);
    const namesArray = filesNames?.split("|");
    namesArray.splice(index, 1);
    setFilesNames(namesArray?.join("|"));
  };

  const showDocumentsNames = (files, setFiles, filesNames, setFilesNames) => {
    return filesNames?.split("|")?.map((n, index) => (
      <div key={index} className={Style.uploadedFile}>
        <div className={Style.uploadedFile__name}>{n}</div>
        <div className={Style.uploadedFile__line} />
        <div className={Style.uploadedFile__removeBtn}>
          <img
            alt=""
            src={XIcon}
            height="25px"
            width="25px"
            onClick={() => {
              removeDocument(files, setFiles, filesNames, setFilesNames, index);
            }}
          />
        </div>
      </div>
    ));
  };

  const setParkingZoneAdmin = (id) => {
    setKeycloakAdminId(id);
    if (managersIds?.includes(id)) {
      setManagersIds([...managersIds?.filter((m) => m !== id)]);
    }
  };

  const renderManagers = () => {
    const getListOfManagers = (managers, ordinalNumber) => {
      if (ordinalNumber === 1) return managers;
      return managers.filter(
        (m) => !managersIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
      <div>
        {managersIds.map((manager, index) => {
          const ordinalNumber = index + 1;

          return (
            <div key={index} className={Style.manager}>
              <div className={Style.manager__select}>
                <Controller
                    name={`parkingZoneManager_${managersComboBoxNames[index]}`}
                    control={control}
                    render={() => (
                        <Autocomplete
                            className={Style.textStyleSelectEditModal}
                            id={`parkingZoneManager_${managersComboBoxNames[index]}`}
                            onChange={(e,value)  => {
                              if(value) {
                                managersIds[index] = value.id;
                                setManagersIds([...managersIds]);
                              }
                            }}
                            disableClearable
                            value = {managers.find(x=>x.id===manager) || null}
                            renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                            options={getListOfManagers(managers, ordinalNumber).filter((m) => m.id !== keycloakAdminId)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                        />
                    )}
                />
              </div>
              <div className={Style.manager__removeBtn}>
                <img
                  alt=""
                  src={XIcon}
                  height="25px"
                  width="25px"
                  onClick={(e) => {
                    setManagersIds([
                      ...managersIds.filter((m, i) => i !== index),
                    ]);
                    managersComboBoxNames.splice(index, 1);
                    setManagersComboBoxNames([...managersComboBoxNames]);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const renderViewers = () => {
    const getListOfViewers = (viewers, ordinalNumber) => {
      if (ordinalNumber === 1) return viewers;
      return viewers.filter(
          (m) => !viewersIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
        <div>
          {viewersIds.map((viewer, index) => {
            const ordinalNumber = index + 1;

            return (
                <div key={index} className={Style.manager}>
                  <div className={Style.manager__select}>
                    <Controller
                        name={`parkingZoneViewer_${viewersComboBoxNames[index]}`}
                        control={control}
                        render={() => (
                            <Autocomplete
                                className={Style.textStyleSelectEditModal}
                                id={`parkingZoneManager_${viewersComboBoxNames[index]}`}
                                onChange={(e,value)  => {
                                  if(value) {
                                    viewersIds[index] = value.id;
                                    setViewersIds([...viewersIds]);
                                  }
                                }}
                                disableClearable
                                value = {viewers.find(x=>x.id===viewer) || null}
                                renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                                options={getListOfViewers(viewers, ordinalNumber).filter((m) => m.id !== keycloakAdminId)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                            />
                        )}
                    />
                  </div>
                  <div className={Style.manager__removeBtn}>
                    <img
                        alt=""
                        src={XIcon}
                        height="25px"
                        width="25px"
                        onClick={(e) => {
                          setViewersIds([
                            ...viewersIds.filter((m, i) => i !== index),
                          ]);
                          viewersComboBoxNames.splice(index, 1);
                          setViewersComboBoxNames([...viewersComboBoxNames]);
                        }}
                    />
                  </div>
                </div>
            );
          })}
        </div>
    );
  };

  const saveParkingZone = async () => {
    const formData = new FormData();

    for (let i = 0; i < powerOfAttorney?.length; i++) {
      formData.append("powerOfAttorney", powerOfAttorney[i]);
    }
    for (let i = 0; i < landRegister?.length; i++) {
      formData.append("landRegister", landRegister[i]);
    }
    for (let i = 0; i < parkingPhoto?.length; i++) {
      formData.append("parkingPhoto", parkingPhoto[i]);
    }
    for (let i = 0; i < documentation?.length; i++) {
      formData.append("documentation", documentation[i]);
    }
    formData.append("documentationName", documentationName);
    formData.append("powerOfAttorneyName", powerOfAttorneyName);
    formData.append("landRegisterName", landRegisterName);
    formData.append("parkingPhotoName", parkingPhotoName);

    let data2 = {
      address,
      keycloakAdminId: isLoggedUserSysadmin ? keycloakAdminId : loggedUser.id,
      numberOfParkingPlaces,
      parkingZoneName,
      price,
      parkingPermitDurationTime: parkingPermitEnabled
        ? parkingPermitTime?.$H * 60 + parkingPermitTime?.$m
        : null,
      freezeTime: parkingPermitEnabled
        ? freezeTime?.$H * 60 + freezeTime?.$m
        : null,
      reminderFee,
      lastReminderFee,
      additionalCost,
      reminderPaymentPeriod,
      lastReminderPaymentPeriod,
      parkingZoneText: editorRef.current.getContent(),
      payee,
      iban,
      bic,
      financialInstitution,
      paymentPeriod,
      managersIds,
      viewersIds,
      clientId,
      useClientDocs,
      objectionEnabled,
      staticPinCode,
      additionalInfoForTickets,
      dateOfCourtDecision,
      publicationInCantonalNewspaper,
      pzComment,
      salesArea,
      contractDate,
      notedBy,
      injuredPerson,
      representedBy,
      relationToProperty,
      additionalInfo,
      parkingPayZone,
      customer,
      viacarIntegrationImmediately,
      videoAnalyzed,
      automaticSignedOut: automaticSignedOut.getHours(),
      markers: markers.map((marker) => {
        return { latitude: marker.center.lat, longitude: marker.center.lng };
      }),
      useMarkerForProximityCheck,
      parkingViolationType: parkingViolationTypeWatch,
    };
    console.log(data2);
    formData.append(
      "parkingZone",
      new Blob([JSON.stringify(data2)], {
        type: "application/json",
      })
    );

    await dispatch(addParkingZone(formData, props.onClose));
  };

  useEffect(() => moveMapCenterByCountry(markers, countryWatch, map), [countryWatch]);

  // noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
  const memoizedDebouncedGeocoding = useCallback(
      _.debounce((map, geocoder, address, setMarkersCallback) => {

            const formattedAddress = formatAddress(address);
            NotificationManager.info(t('EditParkingZoneModal.geocodingAddressLong', {formattedAddress: formattedAddress}), t('EditParkingZoneModal.geocodingAddress'), 1500);
            geocodeAddressSetMarkers(map, geocoder, address, setMarkersCallback)
                .then(_ => {
                  // loader...
                })
                .finally({
                  // loader

                })
          },
          ADDRESS_GEOCODING_DELAY), []);

  const onAddressAdded = (newAddress) => {
    setAddress(newAddress);

    if (newAddress) {
      // address is valid, begin geocoding sometimes in the future
      memoizedDebouncedGeocoding(map, geocoder, newAddress, setMarkers);
    }
  }

  const currencyDisplay = formatCurrencyCode(countryWatch)

  const priceLabel = t("ParkingZones.price") + " " + currencyDisplay;
  const reminderFeeLabel = t("ParkingZones.reminderFee") + " " + currencyDisplay;
  const lastReminderFeeLabel = t("EditParkingZoneModal.lastReminderFee") + " " + currencyDisplay;
  const additionalCostLabel = t("ParkingZones.additionalCost") + " " + currencyDisplay;

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={newModalStyle({ width: "70%", maxWidth: "900px" })}
    >
      <div className="modal__headerLine" />
      <div className={cn("modal__title", Style.modal__title)}>
        {props.title}
      </div>

      <form onSubmit={handleSubmit(saveParkingZone)}>
        <div className={Style.formContainer}>
          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("ParkingZones.basicInfo")}
            </div>
            <div className={Style.pzNumberNameContainer}>
              <div className={Style.pzNameContainer}>
                <InputFieldWithLabel
                  label={t("ParkingZones.pzName")}
                  name="parkingZoneName"
                  autoComplete={"off"}
                  registerName="parkingZoneName"
                  register={register}
                  required
                />
              </div>
              <div className={Style.staticPinCodeContainer}>
                <InputFieldWithLabel
                  label={t("ParkingZones.StaticCode")}
                  name="staticPinCode"
                  style={{ marginLeft: "20px" }}
                  registerName="staticPinCode"
                  register={register}
                  pattern="[1-9][0-9][0-9][0-9]"
                  placeholder={t("ParkingZones.StaticCodePlaceholder")}
                />
              </div>
            </div>
            <div className={Style.pzThreeInputsContainer}>
                <div className={Style.container}>
                     <InputFieldWithLabel
                       label={t("ParkingZones.numberOfParkingPlaces")}
                       type="number"
                       name="numberOfParkingPlaces"
                       registerName="numberOfParkingPlaces"
                       register={register}
                       required
                     />
                </div>
                <div className={Style.pricePaymentContainer}>
                    <div
                      className={cn(
                        Style.container,
                        Style["container--margineRight"]
                      )}
                    >
                        <InputFieldWithLabel
                          label={additionalCostLabel}
                          name="additionalCost"
                          type="number"
                          registerName="additionalCost"
                          defaultValue={10}
                          register={register}
                          required
                        />
                    </div>
                    <div className={Style.container}>
                        <SelectWithLabel
                            label={t("ParkingZones.parkingViolationType")}
                            name="parkingViolationType"
                            registerName="parkingViolationType"
                            register={register}
                            required={true}
                            whiteBackground
                            defaultValue={parkingViolationTypes[0].value}
                            hideDefaultOption={true}
                            options={parkingViolationTypes.map((option) => (
                              <option key={option.value} value={option.value}>
                                {(option.label)}
                              </option>
                            ))}
                        />
                    </div>
                </div>
            </div>
            <div className={Style.pzThreeInputsContainer}>
                <div className={Style.container}>
                    <InputFieldWithLabel
                      label={priceLabel}
                      name="price"
                      type="number"
                      registerName="price"
                      register={register}
                      required
                    />
                </div>
                <div className={Style.pricePaymentContainer}>
                    <div
                      className={cn(
                        Style.container,
                        Style["container--margineRight"]
                      )}
                    >
                        <InputFieldWithLabel
                          label={reminderFeeLabel}
                          type="number"
                          name="reminderFee"
                          registerName="reminderFee"
                          defaultValue={10}
                          register={register}
                          required
                        />
                    </div>
                    <div className={Style.container}>
                        <InputFieldWithLabel
                          label={lastReminderFeeLabel}
                          type="number"
                          name="lastReminderFee"
                          registerName="lastReminderFee"
                          defaultValue={10}
                          register={register}
                          required
                        />
                    </div>
                </div>
            </div>
            <div className={Style.pzThreeInputsContainer}>
                <div className={Style.container}>
                    <InputFieldWithLabel
                      label={t("ParkingZones.paymentPeriod")}
                      name="paymentPeriod"
                      type="number"
                      defaultValue="14"
                      registerName="paymentPeriod"
                      register={register}
                      required
                    />
                </div>
                <div className={Style.pricePaymentContainer}>
                    <div
                      className={cn(
                        Style.container,
                        Style["container--margineRight"]
                      )}
                    >
                        <InputFieldWithLabel
                          label={t("ParkingZones.reminderPaymentPeriod")}
                          name="reminderPaymentPeriod"
                          type="number"
                          registerName="reminderPaymentPeriod"
                          defaultValue={7}
                          register={register}
                          required
                        />
                    </div>
                    <div className={Style.container}>
                        <InputFieldWithLabel
                          label={t("EditParkingZoneModal.lastReminderPaymentPeriod")}
                          name="lastReminderPaymentPeriod"
                          type="number"
                          registerName="lastReminderPaymentPeriod"
                          defaultValue={7}
                          register={register}
                          required
                        />
                    </div>
                </div>
            </div>
            <div className={Style.pzThreeInputsContainer}>
                <div className="objectionEnabledCheckbox">
                  <input
                    type="checkBox"
                    className="checkboxObjectionEnabled"
                    onChange={() => {
                      setObjectionEnabled(!objectionEnabled);
                    }}
                    checked={objectionEnabled}
                  />
                  <div className="objectionEnabled" style={{ fontSize: 14 }}>
                    {t("ParkingZones.objectionEnabled")}
                  </div>
                </div>
                <div className="objectionEnabledCheckbox">
                  <input
                    disabled={videoAnalyzed}
                    type="checkBox"
                    className="checkboxObjectionEnabled"
                    onChange={() => {
                      setAdditionalInfoForTickets(!additionalInfoForTickets);
                    }}
                    checked={additionalInfoForTickets}
                  />
                  <div className="objectionEnabled" style={{ fontSize: 14 }}>
                    {t("ParkingZones.additionalInfoForTickets")}
                  </div>
                </div>
                <div className="objectionEnabledCheckbox">
                  <input
                    type="checkBox"
                    className="checkboxObjectionEnabled"
                    onChange={() => {
                      setViacarIntegrationImmediately(
                        !viacarIntegrationImmediately
                      );
                    }}
                    checked={viacarIntegrationImmediately}
                  />
                  <div
                    className="objectionEnabled"
                    style={{ fontSize: 14 }}
                  >
                    {t("ParkingZones.viacarIntegrationImmediately")}
                  </div>
                </div>
                <div className="objectionEnabledCheckbox">
                  <input
                      disabled={additionalInfoForTickets}
                      type="checkBox"
                      className="checkboxObjectionEnabled"
                      onChange={() => {
                        setVideoAnalyzed(
                            !videoAnalyzed
                        );
                      }}
                      checked={videoAnalyzed}
                  />
                  <div
                      className="objectionEnabled"
                      style={{ fontSize: 14 }}
                  >
                    {t("ParkingZones.videoAnalysis")}
                  </div>
                </div>
            </div>

            <div className={Style.pzPermitTimeFreezTimeContainer}>
              <div className={Style.parkingPermitEnabled}>
                <input
                  type="checkBox"
                  onChange={() => {
                    setParkingPermitEnabled(!parkingPermitEnabled);
                  }}
                  checked={parkingPermitEnabled}
                />
                <div
                  className={Style.parkingPermitEnabledLabel}
                  style={{ fontSize: 14 }}
                >
                  {t("ParkingZones.enableParkingPermitAndFreezeTime")}
                </div>
              </div>

              <div className={Style.pzPermitTimeContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePickerInputWithLabel
                    label={t("ParkingZones.parkingPermitTime")}
                    value={parkingPermitTime}
                    setTime={setParkingPermitTime}
                    disabled={parkingPermitEnabled}
                  />
                </LocalizationProvider>
              </div>
              <div className={Style.pzPermitFreezeTimeContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePickerInputWithLabel
                    label={t("ParkingZones.freezeTime")}
                    value={freezeTime}
                    setTime={setFreezeTime}
                    disabled={parkingPermitEnabled}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>

          <div className={Style.section}>
            <AddressesComponent
                onAddressAdded={onAddressAdded}
                watch={watch}
                register={register}
                useGeocode={false}
            />
            <LabelWithHR label={t("ParkingZones.location")}/>
            <div className={Style.section__map}>
              <GoogleMapReact
                  defaultCenter={defaultMarker.center}
                  defaultZoom={defaultMarker.zoom}
                  yesIWantToUseGoogleMapApiInternals
                  options={markers.length > 0 ? colorMapOptions : dimmedMapOptions}
                  onClick={(value) => {
                    if (!manualParkingZoneMarkerEnabled) {
                      return;
                    }

                    if (markers.length > 0) {
                      return;
                    }
                    setMarkers([
                      ...markers,
                      {
                        center: {lat: value.lat, lng: value.lng}
                      },
                    ]);
                  }}
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                    libraries: ["geocoding"],
                    language: i18n.language,
                  }}
                  onGoogleApiLoaded={({map, maps}) => {
                    setMap(map);
                    setGeocoder(new maps.Geocoder());
                  }}
              >
                {markers?.map((marker) => (
                    <Marker
                        lat={marker.center.lat}
                        lng={marker.center.lng}
                        onClick={() => {
                          if (!manualParkingZoneMarkerEnabled) {
                            return;
                          }
                          setMarkers([])
                        }}
                    />
                ))}
              </GoogleMapReact>
            </div>
            <div className="objectionEnabledCheckbox">
              <input
                  type="checkBox"
                  className="checkboxObjectionEnabled"
                  disabled={videoAnalyzed || !markers?.length}
                  onChange={() => {
                    setUseMarkerForProximityCheck(
                        !useMarkerForProximityCheck
                    );
                  }}
                  checked={useMarkerForProximityCheck}
              />
              <div
                  className="objectionEnabled"
                  style={{fontSize: 14}}
              >
                {t("ParkingZones.proximityCheck")}
              </div>
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("ParkingZones.parkingZoneText")}
            </div>
            <div className={Style.editor}>
              <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue=""
                  init={{
                    width: "100%",
                    height: 250,
                    menubar: false,
                    paste_as_text: true,
                    language: "de",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family: Gilroy-Medium;font-size: 16px;line-height:20px;}",
                }}
              />
            </div>
          </div>
          {isLoggedUserSysadmin && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "30px",
                  marginLeft: "30px",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ zIndex: 3, flex: 1 }}>
                  <LabelWithHR label={t("ParkingZones.contractDate")} />
                  <Controller
                    name="contractDate"
                    control={control}
                    render={() => (
                      <DatePicker
                        selected={contractDate}
                        dateFormat="dd.MM.yyyy"
                        popperPlacement="top"
                        className={Style.textStyleInputEditModal}
                        locale="de"
                        onChange={(date) => setContractDate(date)}
                      />
                    )}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: "40px" }}>
                  <InputFieldWithLabel
                    label={t("ParkingZones.salesArea")}
                    name="salesArea"
                    registerName="salesArea"
                    register={register}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "30px",
                  marginLeft: "30px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <InputFieldWithLabel
                    label={t("ParkingZones.parkingPayZone")}
                    name="parkingPayZone"
                    type="number"
                    registerName="parkingPayZone"
                    register={register}
                  />
                </div>

                {/* Ovo neka bude klijent */}
                <div style={{ flex: 1, marginLeft: "40px" }}>
                  <InputFieldWithLabel
                    label={t("Clients.Client")}
                    name="customer"
                    type="text"
                    registerName="customer"
                    register={register}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "30px",
                  marginLeft: "30px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <TextareaWithLabel
                    label={t("ParkingZones.pzComment")}
                    name="pzComment"
                    textAreaStyle={{
                      minHeight: "60px",
                      boxShadow: "none",
                      border: "1px solid #c7c7c7",
                    }}
                    registerName="pzComment"
                    register={register}
                  />
                </div>
              </div>
            </>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <InputFieldWithLabel
                label={t("ParkingZones.representedBy")}
                name="representedBy"
                registerName="representedBy"
                register={register}
              />
            </div>
            <div style={{ flex: 1, marginLeft: "40px" }}>
              <InputFieldWithLabel
                label={t("ParkingZones.relationToProperty")}
                name="relationToProperty"
                registerName="relationToProperty"
                register={register}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <TextareaWithLabel
                label={t("ParkingZones.notedBy")}
                name="notedBy"
                textAreaStyle={{
                  minHeight: "60px",
                  boxShadow: "none",
                  border: "1px solid #c7c7c7",
                }}
                registerName="notedBy"
                register={register}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <TextareaWithLabel
                label={t("ParkingZones.injuredPerson")}
                name="injuredPerson"
                textAreaStyle={{
                  minHeight: "60px",
                  boxShadow: "none",
                  border: "1px solid #c7c7c7",
                }}
                registerName="injuredPerson"
                register={register}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <TextareaWithLabel
                label={t("ParkingZones.additionalInfo")}
                name="additionalInfo"
                textAreaStyle={{
                  minHeight: "60px",
                  boxShadow: "none",
                  border: "1px solid #c7c7c7",
                }}
                registerName="additionalInfo"
                register={register}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "30px",
              marginLeft: "30px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ zIndex: 2, flex: 1 }}>
              <LabelWithHR label={t("ParkingZones.dateOfCourtDecision")} />
              <Controller
                name="dateOfCourtDecision"
                control={control}
                render={() => (
                  <DatePicker
                    selected={dateOfCourtDecision}
                    popperPlacement="bottom"
                    dateFormat="dd.MM.yyyy"
                    className={Style.textStyleInputEditModal}
                    locale="de"
                    onChange={(date) => setDateOfCourtDecision(date)}
                  />
                )}
              />
            </div>
            <div style={{ zIndex: 2, flex: 1, marginLeft: "40px" }}>
              <LabelWithHR
                label={t("ParkingZones.publicationInCantonalNewspaper")}
              />
              <Controller
                name="publicationInCantonalNewspaper"
                control={control}
                render={() => (
                  <DatePicker
                    selected={publicationInCantonalNewspaper}
                    popperPlacement="bottom"
                    dateFormat="dd.MM.yyyy"
                    className={Style.textStyleInputEditModal}
                    locale="de"
                    onChange={(date) => setPublicationInCantonalNewspaper(date)}
                  />
                )}
              />
            </div>
            <div style={{ zIndex: 2, flex: 1, marginLeft: "40px" }}>
              <LabelWithHR label={t("ParkingZones.automaticSignedOut")} />
              <Controller
                name="automaticSignedOut"
                control={control}
                render={() => (
                  <DatePicker
                    selected={automaticSignedOut}
                    popperPlacement="bottom"
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    timeIntervals={60}
                    className={Style.textStyleInputEditModal}
                    locale="de"
                    onChange={(date) => setAutomaticSignedOut(date)}
                  />
                )}
              />
            </div>
          </div>

          <div className={cn(Style.section, Style["section--payment"])}>
            <div className={Style.section__title}>
              {t("ParkingZones.paymentInfo")}
            </div>
            <div className={Style.paymentSection__rowContainer}>
              <div
                className={cn(
                  Style.container,
                  Style["container--margineRight"]
                )}
              >
                <InputFieldWithLabel
                  label="IBAN"
                  name="iban"
                  registerName="iban"
                  register={register}
                />
              </div>
              <div
                className={cn(Style.container, Style["container--margineLeft"])}
              >
                <InputFieldWithLabel
                  label="BIC"
                  name="bic"
                  registerName="bic"
                  register={register}
                />
              </div>
            </div>
            <div className={Style.paymentSection__rowContainer}>
              <div
                className={cn(
                  Style.container,
                  Style["container--margineRight"]
                )}
              >
                <InputFieldWithLabel
                  label={t("ParkingZones.Payee")}
                  name="payee"
                  registerName="payee"
                  register={register}
                />
              </div>
              <div
                className={cn(Style.container, Style["container--margineLeft"])}
              >
                <InputFieldWithLabel
                  label={t("ParkingZones.FinancialInstitution")}
                  name="financialInstitution"
                  registerName="financialInstitution"
                  register={register}
                />
              </div>
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("ParkingZones.documents")}
            </div>
            <div className={Style.container}>
              <>
                <label htmlFor="file-upload" className={Style.fileUpload}>
                  {t("ParkingZones.PowerOfAttorney")}
                </label>
                {powerOfAttorneyName !== "" && (
                  <label>
                    {powerOfAttorneyName === "" ? null : (
                      <div>
                        {showDocumentsNames(
                          powerOfAttorney,
                          setPowerOfAttorney,
                          powerOfAttorneyName,
                          setPowerOfAttorneyName
                        )}
                      </div>
                    )}
                  </label>
                )}

                <input
                  id="file-upload"
                  type="file"
                  accept=".pdf"
                  className={Style.uploadProof}
                  multiple
                  onChange={(e) => {
                    documentUpload(
                      e,
                      powerOfAttorney,
                      setPowerOfAttorney,
                      powerOfAttorneyName,
                      setPowerOfAttorneyName
                    );
                  }}
                />
              </>
              <>
                <label htmlFor="landRegister" className={Style.fileUpload}>
                  {t("ParkingZones.LandRegister")}
                </label>
                {landRegisterName !== "" && (
                  <label>
                    {landRegisterName === "" ? null : (
                      <div>
                        {showDocumentsNames(
                          landRegister,
                          setLandRegister,
                          landRegisterName,
                          setLandRegisterName
                        )}
                      </div>
                    )}
                  </label>
                )}
                <input
                  id="landRegister"
                  type="file"
                  accept=".pdf"
                  className="landRegister"
                  multiple
                  onChange={(e) => {
                    documentUpload(
                      e,
                      landRegister,
                      setLandRegister,
                      landRegisterName,
                      setLandRegisterName
                    );
                  }}
                />
              </>

              <>
                <label htmlFor="parkingPhoto" className={Style.fileUpload}>
                  {t("ParkingZones.PZPhotos")}
                </label>
                {parkingPhotoName !== "" && (
                  <label>
                    {parkingPhotoName === "" ? null : (
                      <div>
                        {showDocumentsNames(
                          parkingPhoto,
                          setParkingPhoto,
                          parkingPhotoName,
                          setParkingPhotoName
                        )}
                      </div>
                    )}
                  </label>
                )}

                <input
                  id="parkingPhoto"
                  type="file"
                  accept="image/jpg, image/png, image/jpeg"
                  className="parkingPhoto"
                  multiple
                  onChange={(e) => {
                    documentUpload(
                      e,
                      parkingPhoto,
                      setParkingPhoto,
                      parkingPhotoName,
                      setParkingPhotoName
                    );
                  }}
                />
                <label htmlFor="documentation" className={Style.fileUpload}>
                  {t("ParkingZones.PZDocumentation")}
                </label>
                {documentationName !== "" && (
                  <label>
                    {documentationName === "" ? null : (
                      <div>
                        {showDocumentsNames(
                          documentation,
                          setDocumentation,
                          documentationName,
                          setDocumentationName
                        )}
                      </div>
                    )}
                  </label>
                )}
                <input
                  id="documentation"
                  type="file"
                  accept=".pdf, .docx"
                  className="documentation"
                  multiple
                  onChange={(e) => {
                    documentUpload(
                      e,
                      documentation,
                      setDocumentation,
                      documentationName,
                      setDocumentationName
                    );
                  }}
                />
              </>
            </div>
          </div>

          <div className={Style.section}>

            <div
              className={cn(
                Style.section__title,
                Style["section__title--admin"]
              )}
            >
              {t("Clients.Client")}
            </div>

            {isLoggedUserSysadmin ? (
            <div>
                <div className={Style.manager}>
                    <div className={Style.manager__select}>
                        <Controller
                            name="clientId"
                            control={control}
                            render={() => (
                                <Autocomplete
                                    className={Style.textStyleSelectEditModal}
                                    id={"clientId"}
                                    onChange={(e,value)  => {
                                      if(value) {
                                        setClientId(value.id);
                                        setValue("customer", `${value.name}`);
                                      }
                                    }}
                                    disableClearable
                                    value = {clients.find(x=>x.id===clientId) || null}
                                    renderInput={(params) => <TextField {...params} placeholder={t("Clients.Choose")}/>}
                                    options={clients}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(x) => `${x.name} | ${x.representativeName}`}
                                />
                            )}
                        />
                    </div>
                    <div className={Style.manager__removeBtn} onClick={() => {
                                                                           setClientId(null);
                                                                           setUseClientDocs(false);
                                                                           setValue("customer", "");
                        }}>
                        <img
                            alt=""
                            src={XIcon}
                            height="25px"
                            width="25px"
                        />
                    </div>
                </div>
                <div className="objectionEnabledCheckbox">
                  <input
                      type="checkBox"
                      className="checkboxObjectionEnabled"
                      disabled={!clientId}
                      onChange={() => {
                        setUseClientDocs(
                            !useClientDocs
                        );
                      }}
                      checked={useClientDocs}
                  />
                  <div
                      className="objectionEnabled"
                      style={{ fontSize: 14 }}
                  >
                    {t("Clients.UseClientDocs")}
                  </div>
                </div>
            </div>
            ) : (
              <div className={Style.text}>
                {clients.find(client => client.id === clientId)?.name || ""}
              </div>
            )}

<div
                className={cn(
                    Style.section__title,
                    Style["section__title--admin"]
                )}
            >
              {t("Viewer.Viewers")}
            </div>
            {renderViewers()}
            <button
                type="button"
                className={
                  viewersIds?.includes("0")
                      ? cn(
                          Style.addManagerButton,
                          Style["addManagerButton--disabled"]
                      )
                      : cn(
                          Style.addManagerButton,
                          Style["addManagerButton--enabled"]
                      )
                }
                onClick={() => {
                  setViewersIds([...viewersIds, "0"]);
                  setViewersComboBoxNames([
                    ...viewersComboBoxNames,
                    viewerCounter + 1,
                  ]);
                  setViewerCounter(viewerCounter + 1);
                }}
                disabled={viewersIds?.includes("0")}
            >
              {"+"+t("Viewer.addViewer")}
            </button>


            <div
              className={cn(
                Style.section__title,
                Style["section__title--admin"]
              )}
            >
              {t("ParkingZones.chooseAdmin")}
            </div>

            {isLoggedUserSysadmin ? (
                <Controller
                    name="keycloakAdminId"
                    control={control}
                    render={() => (
                        <Autocomplete
                            className={Style.textStyleSelectEditModal}
                            id={"keycloakAdminId"}
                            onChange={(e,value)  => {
                              if(value) {
                                setParkingZoneAdmin(value.id);
                              }
                            }}
                            disableClearable
                            value = {admins.find(x=>x.id===keycloakAdminId) || null}
                            renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                            options={admins}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                        />
                    )}
                />
            ) : (
              <div className={Style.text}>
                {loggedUser?.firstName + " " + loggedUser?.lastName}
              </div>
            )}

            <div
              className={cn(
                Style.section__title,
                Style["section__title--admin"]
              )}
            >
              {"PZ Manager"}
            </div>

            {renderManagers()}

            <button
              type="button"
              className={
                managersIds?.includes("0")
                  ? cn(
                      Style.addManagerButton,
                      Style["addManagerButton--disabled"]
                    )
                  : cn(
                      Style.addManagerButton,
                      Style["addManagerButton--enabled"]
                    )
              }
              onClick={() => {
                setManagersIds([...managersIds, "0"]);
                setManagersComboBoxNames([
                  ...managersComboBoxNames,
                  counter + 1,
                ]);
                setCounter(counter + 1);
              }}
              disabled={managersIds?.includes("0")}
            >
              {"+ Neue Manager"}
            </button>

            <div className={Style.buttonContainer}>
              <Button
                style={{
                  width: "25%",
                }}
                buttonStyle={{
                  width: "100%",
                }}
                disabled={isSubmitDisabled}
                text={t("ParkingZones.saveParkingZone")}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default AddParkingZoneModal;
