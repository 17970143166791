import React from "react";
import { useTranslation } from "react-i18next";
import { NavDropdown } from "react-bootstrap";
import { useGlobalState} from "../GlobalContext";
const LanguageMenu = (props) => {
  const { i18n } = useTranslation();
  const { state } = useGlobalState();
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("preservedState", JSON.stringify(state));
    window.location.reload();
  };

  const selectedLanguage =
    localStorage.getItem("beppoSelectedLanguage") || "DE";

  return (
    <>
      {selectedLanguage.toUpperCase()!= "DE" && 
      <>
        <NavDropdown.Item
          onClick={() => {
            handleClick("de");
            localStorage.setItem("beppoSelectedLanguage", "de");
          }}
        >
          DE
        </NavDropdown.Item>
        <NavDropdown.Divider /> 
      </>}
      {selectedLanguage.toUpperCase()!= "EN" && 
      <>
        <NavDropdown.Item
          onClick={() => {
            handleClick("en");
            localStorage.setItem("beppoSelectedLanguage", "en");
          }}
        >
          EN
        </NavDropdown.Item>
        <NavDropdown.Divider />
      </>}
      {
      
      selectedLanguage.toUpperCase()!= "FR" &&
      <> 
        <NavDropdown.Item
          onClick={() => {
            handleClick("fr");
            localStorage.setItem("beppoSelectedLanguage", "fr");
          }}
        >
          FR
        </NavDropdown.Item>
        <NavDropdown.Divider />
      </> }
        {selectedLanguage.toUpperCase()!= "IT" &&
        <>
          <NavDropdown.Item
              onClick={() => {
                  handleClick("it");
                  localStorage.setItem("beppoSelectedLanguage", "it");
              }}
          >
              IT
          </NavDropdown.Item>
        </>}
    </>
  );
};

export default LanguageMenu;
