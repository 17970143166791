import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { MainListItems, NavigationItems } from "./listItems";
import BeppoLogo from "../common/assets/beppo-logo-119x47.svg";
import { version } from "../../package.json";
import Style from "./sidebar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllRequests } from "../actions/requests";
import {ROLES} from "../consts";
import keycloak from "../keycloak";
import {useAuth} from "react-oidc-context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "white",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    overflow: "hidden",
  },
  appBar: {
    zIndex: 3,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "white",
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "white",
    float: "right",
    alignItems: "flex-end",
  },
  menuButton: {
    marginRight: 36,
    paddingLeft: 20,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: "#005a92",
    border: "white",
    color: "white",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));
export default function Sidebar() {
  const auth = useAuth();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const { requests } = useSelector((state) => state.requests);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!keycloak.hasRealmRole(ROLES.GAS_STATION_VIEWER, auth) && !keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth)) {
      if(!auth.isLoading && auth.isAuthenticated) {
        dispatch(getAllRequests());
      }
    }
  }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ boxShadow: "none" }}
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={(classes.toolbar, Style.toolbar)}>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            style={{ justifyContent: "flex-start" }}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>

          <div>
            <NavigationItems />
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          {open ? (
            <>
              <img
                className="img-fluid"
                style={{ marginLeft: "20px" }}
                src={BeppoLogo}
                id="beppoLogo"
                alt="beppo-logo"
              />
              <IconButton
                onClick={handleDrawerClose}
                style={{
                  marginRight: "-20px",
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </>
          ) : (
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          <MainListItems requestsNumber={requests.length} />
          <Divider />
        </List>

        {open && (
          <>
            <div className={Style.support}>
              <div style={{ fontWeight: 600 }}>Support:</div>
              <div style={{ fontSize: 15 }}>+41 44 515 53 99</div>
              <div style={{ fontSize: 14 }}>services@mybeppo.ch</div>
            </div>
            <div className={Style.version}>beppo v{version}</div>
          </>
        )}
      </Drawer>
    </div>
  );
}
