import React, { createContext, useState, useContext } from "react";

// Create a Context
const GlobalContext = createContext();

// Provider Component
export const GlobalProvider = ({ children }) => {
  const storedState = JSON.parse(localStorage.getItem("preservedState")) || null;
  const [state, setState] = useState(storedState? storedState:{
    currency: "CHF",
    country: "CH",
  });

  // Function to update state
  const updateState = (updates) => {
    setState((prevState) => ({ ...prevState, ...updates }));
  };

  return (
    <GlobalContext.Provider value={{ state, updateState }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom Hook to use the Global State
export const useGlobalState = () => useContext(GlobalContext);