import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../sidebar/sidebar";
import MaterialTable from "@material-table/core";
import {
  useStyles,
  useStylesForMaterial,
  toolbarStyles,
  materialTableStyle,
  paginationStyle,
} from "../../reusable/styles";
import { useTranslation } from "react-i18next";
import Pagination from "../../reusable/pagination";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCreateParkingZoneErrorModal,
  closeUpdateParkingZoneErrorModal,
  deletePZ,
  getAllParkingZones,
  getAllParkingZonesForPZAdmin,
} from "../../actions/parkingZone";
import { optionsGeneral } from "../../reusable/optionsForTables";
import LoadingIndicator from "../../reusable/loadingIndicator";
import { ROLES } from "../../consts";
import { changeTabInSidebar } from "../../actions/sidebar";
import Style from "./parkingZones.module.css";
import { ParkingZoneColumns } from "../../columns/parkingZoneColumns";
import EditParkingZoneModal from "./editParkingZoneModal";
import ParkingZoneDetailsModal from "./parkingZoneDetailsModal";
import AddParkingZoneModal from "./addParkingZoneModal";
import ErrorModal from "../../common/modal/errorModal";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import keycloak from "../../keycloak";
import {useAuth} from "react-oidc-context";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import { useGlobalState} from "../../GlobalContext";

export default function ParkingZones() {
  const auth = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const classesForm = toolbarStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { parkingZones } = useSelector((state) => state.parkingZone);
  const { parkingZoneCreateError, parkingZoneUpdateError } = useSelector(
    (state) => state.parkingZone
  );
  const { isLoading } = useSelector((state) => state.loader);
  const [parkingZoneEditModalOpen, setParkingZoneEditModalOpen] =
    useState(false);
  const [selectedParkingZoneId, setSelectedParkingZoneId] = useState("");
  const [parkingZoneAddModalOpen, setParkingZoneAddModalOpen] = useState(false);

  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };
  const { state, updateState } = useGlobalState();

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const [parkingZoneDetailsModalOpen, setParkingZoneDetailsModalOpen] =
    useState(false);

  const deleteParkingZone = async (id) => {
    await dispatch(deletePZ(id));
  };

  useEffect(() => {
    dispatch(changeTabInSidebar(0));
  }, [dispatch]);

  useEffect(() => {
    if (keycloak.hasRealmRole(ROLES.SYSADMIN, auth)) {
      dispatch(getAllParkingZones(state.country));
    } else {
      dispatch(getAllParkingZonesForPZAdmin(state.country));
    }
  }, [dispatch, state.country]);

  const toolbar = (props) => {
    return (
      <div className={classesForm.root}>
        <Grid container direction="row" className={Style.header}>
          <p className={Style.header__text}>{t("ParkingZones.ParkingZones")}</p>
            {(keycloak.hasRealmRole(ROLES.SYSADMIN, auth) ||
                keycloak.hasRealmRole(ROLES.PARKING_ADMIN, auth)) && (
                <button
                  className="addEntityButton"
                  onClick={() => {
                    setParkingZoneAddModalOpen(true);
                  }}
                >
                  <p className={Style.header__addButtonText}>
                    {t("ParkingZones.New pz")}
                  </p>
                </button>
            )}
        </Grid>
      </div>
    );
  };
  const pagination = (props) => {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  };

  const openParkingZoneEditModal = () => {
    setParkingZoneEditModalOpen(true);
    setParkingZoneDetailsModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {isLoading && <LoadingIndicator />}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="text-center">
                <div className="row content">
                  <div className="col-sm-12">
                    <div className={classes.paper}>
                      <MaterialTable
                        icons={{
                          Filter: () => (
                            <img src={MagnifyingGlass} alt="search" />
                          ),
                        }}
                        searchPlaceholder="Test"
                        title={t("ParkingZones.ParkingZones")}
                        style={materialTableStyle}
                        data={parkingZones}
                        columns={ParkingZoneColumns({
                          history,
                          deleteParkingZone,
                          t,
                          keycloak,
                          setSelectedParkingZoneId,
                          setParkingZoneDetailsModalOpen,
                        })}
                        options={optionsGeneral(height * 0.65, 44)}
                        components={{
                          Toolbar: (props) => toolbar(props),
                          Pagination: (props) => pagination(props),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>

      {parkingZoneAddModalOpen && (
        <AddParkingZoneModal
          open={parkingZoneAddModalOpen}
          closeOnEsc
          onClose={() => setParkingZoneAddModalOpen(false)}
          title={t("ParkingZones.createParkingZone")}
        />
      )}

      {parkingZoneDetailsModalOpen && (
        <ParkingZoneDetailsModal
          open={parkingZoneDetailsModalOpen}
          onClose={() => setParkingZoneDetailsModalOpen(false)}
          parkingZoneId={selectedParkingZoneId}
          openParkingZoneEditModal={openParkingZoneEditModal}
        />
      )}

      {parkingZoneEditModalOpen && (
        <EditParkingZoneModal
          open={parkingZoneEditModalOpen}
          closeOnEsc
          onClose={() => setParkingZoneEditModalOpen(false)}
        />
      )}

      {parkingZoneCreateError && (
        <ErrorModal
          title={t("UnsuccessfulParkingZoneCreaton.title")}
          errorMessage={t("UnsuccessfulParkingZoneCreaton.message")}
          open={parkingZoneCreateError}
          onClose={() => dispatch(closeCreateParkingZoneErrorModal())}
          width="350px"
        />
      )}

      {parkingZoneUpdateError && (
        <ErrorModal
          title={t("UnsuccessfulParkingZoneUpdate.title")}
          errorMessage={t("UnsuccessfulParkingZoneUpdate.message")}
          open={parkingZoneUpdateError}
          onClose={() => dispatch(closeUpdateParkingZoneErrorModal())}
          width="350px"
        />
      )}
    </div>
  );
}
